// Copy from banking-sdk/packages/bank-rn/src/utils/loadingManager.ts and change for h5

export enum ELoadingStatus {
  wait = 1,
  show,
  hide,
}

export interface LoadingItem {
  show(): void;
  close(): void;
  isShow: boolean;
}

interface LoadingType {
  showLoading: (msg?: string) => any;
  closeLoading: () => void;
}

class LoadingManager {
  private _loading: LoadingType;
  private _loadings: LoadingItem[] = [];
  constructor(toast: LoadingType) {
    this._loading = toast;
  }

  private addLoading = (loading: LoadingItem) => {
    this._loadings.push(loading);
    return this._loadings.length;
  };
  private removeLoading = (loading: LoadingItem) => {
    if (!this._loadings.length) {
      return null;
    } else {
      this._loadings = this._loadings.filter((item) => item !== loading);
    }
    return this._loadings[0];
  };
  showLoading = ({
    text,
    maxWaitTime = 30000,
    delay,
  }: {
    text?: string;
    delay?: number;
    maxWaitTime?: number;
  } = {}): LoadingItem => {
    let status: ELoadingStatus = ELoadingStatus.wait;
    let waitTimer: ReturnType<typeof setTimeout> | undefined;
    let delayTimer: ReturnType<typeof setTimeout> | undefined;
    const showTime = Date.now() + (delay ?? 0);
    const loading: LoadingItem = {
      isShow: false,
      show: () => {
        if (status === ELoadingStatus.hide) {
          return;
        }
        const _show = () => {
          this._loading.showLoading(text);
          loading.isShow = true;
          status = ELoadingStatus.show;
        };
        const nextShowTime = showTime - Date.now();
        if (nextShowTime > 50) {
          this._loading.closeLoading();
          delayTimer = setTimeout(() => {
            delayTimer = undefined;
            _show();
          }, nextShowTime);
        } else {
          _show();
        }
      },
      close: () => {
        if (status === ELoadingStatus.hide) {
          return;
        }
        //清理定时器
        if (waitTimer) {
          clearTimeout(waitTimer);
          waitTimer = undefined;
        }
        if (delayTimer) {
          clearTimeout(delayTimer);
          delayTimer = undefined;
        }
        const next = this.removeLoading(loading);
        loading.isShow = false;
        if (status === ELoadingStatus.wait) {
          status = ELoadingStatus.hide;
          return;
        }
        status = ELoadingStatus.hide;
        if (!next) {
          this._loading.closeLoading();
        } else if (!next.isShow) {
          next.show();
        }
      },
    };
    if (maxWaitTime > 0) {
      waitTimer = setTimeout(() => {
        waitTimer = undefined;
        loading.close();
      }, maxWaitTime);
    }
    if (this.addLoading(loading) === 1) {
      loading.show();
    }
    return loading;
  };
  closeAllLoading = () => {
    this._loadings = [];
    this._loading.closeLoading();
  };
}

export default LoadingManager;
