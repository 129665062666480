/* eslint-disable */

(function () {
  if (window.WebViewJavascriptBridge) {
    return;
  }
  var CUSTOM_PROTOCOL_SCHEME = 'wvjbscheme';
  var QUEUE_HAS_MESSAGE = '__WVJB_QUEUE_MESSAGE__';

  var messagingIframe;
  var sendMessageQueue = [];
  var receiveMessageQueue = [];
  var messageHandlers = {};
  var responseCallbacks = {};
  var uniqueId = 1;

  function reset() {
    sendMessageQueue = [];
    receiveMessageQueue = [];
    messageHandlers = {};
    responseCallbacks = {};
    uniqueId = 1;
    window.WebViewJavascriptBridge._messageHandler = null;
  }

  function _createQueueReadyIframe(doc) {
    messagingIframe = doc.createElement('iframe');
    messagingIframe.style.display = 'none';
    messagingIframe.src = CUSTOM_PROTOCOL_SCHEME + '://' + QUEUE_HAS_MESSAGE;
    doc && doc.documentElement && doc.documentElement.appendChild(messagingIframe);
  }

  function init(messageHandler) {
    if (window.WebViewJavascriptBridge._messageHandler) {
      // it's not really an error, fix for many duplicate call cases
      console.warn('WebViewJavascriptBridge.init called twice');
      return null;
    }

    window.WebViewJavascriptBridge._messageHandler = [messageHandler];
    var receivedMessages = receiveMessageQueue;
    receiveMessageQueue = null;
    setTimeout(function () {
      if (receivedMessages) {
        for (var i = 0; i < receivedMessages.length; i++) {
          _dispatchMessageFromObjC(receivedMessages[i]);
        }
      }
    }, 0);
  }

  function send(data, responseCallback) {
    _doSend(
      {
        data: data,
      },
      responseCallback,
    );
  }

  function registerHandler(handlerName, handler, handlerId) {
    if (typeof messageHandlers[handlerName] === 'undefined') {
      messageHandlers[handlerName] = {};
    }

    handlerId = handlerId || handler.toString();
    messageHandlers[handlerName][handlerId] = handler; // We have to do this because Android app will only call hasHandler once inside pageFinished(), and thus later handlers won't be able to register with Android if we don't do this.

    hasHandlerCB(handlerName);
  }

  function unregisterHandler(handlerName, handlerId) {
    if (!handlerId) return;
    var handler = messageHandlers[handlerName];
    if (!handler) return;
    delete handler[handlerId];
    var stillHasHandler = false;

    for (var _temp in handler) {
      if (handler.hasOwnProperty(_temp)) {
        stillHasHandler = true;
        break;
      }
    }

    if (!stillHasHandler) {
      delete messageHandlers[handlerName];
      hasHandlerCB(handlerName);
    }
  }

  var hooks_map = {};

  function addHook(hookFunc) {
    var key = hookFunc.toString();
    if (hooks_map[key]) return;
    hooks_map[key] = hookFunc;
  }

  function delHook(hookFunc) {
    var key = hookFunc.toString();
    delete hooks_map[key];
  }

  function callHandler(handlerName, data, responseCallback) {
    // for(var i=0; i<hooks.length; i++)
    for (var k in hooks_map) {
      if (!hooks_map.hasOwnProperty(k)) continue;

      try {
        hooks_map[k](handlerName, data);
      } catch (ex) {}
    } // !!! When going to full-screen mode, we MUST provide non-webp URL because we do NOT want user to save webp file and share later due to its limited support on iOS devices.

    if (handlerName == 'fullScreenImage' && data) {
      var imageUrls = data['imageUrls'];

      if (imageUrls && imageUrls.length) {
        try {
          for (var i = 0; i < imageUrls.length; i++) {
            var url = imageUrls[i];
            if (typeof url !== 'string' || url.indexOf(window.ITEM_IMAGE_BASE_URL) < 0) continue;
            imageUrls[i] = url.replace('/webp/', '/');
          }
        } catch (e) {}
      }
    }

    _doSend(
      {
        handlerName: handlerName,
        data: data,
      },
      responseCallback,
    );
  }

  function _doSend(message, responseCallback) {
    // Skip bridge cmd if app has pending page update.
    if (window.__appHasPendingCacheUpdate__) return; //if(destroyed) return;

    if (responseCallback) {
      var callbackId = 'cb_' + uniqueId++ + '_' + +new Date();
      responseCallbacks[callbackId] = responseCallback;
      message['callbackId'] = callbackId;
    }

    if (window.navigator.userAgent.toLowerCase().indexOf('android') == -1) {
      // ios
      sendMessageQueue.push(message);
      messagingIframe.src = CUSTOM_PROTOCOL_SCHEME + '://' + QUEUE_HAS_MESSAGE;
    } else if (window.gabridge) {
      window.gabridge.sendMsg(JSON.stringify(message));
    }
  }

  function _fetchQueue() {
    var messageQueueString = JSON.stringify(sendMessageQueue);
    sendMessageQueue = []; //if(destroyed) return [];

    return messageQueueString;
  }

  function _dispatchMessageFromObjC(messageJSON) {
    // Skip bridge cmd if app has pending page update.
    if (window.__appHasPendingCacheUpdate__) return; //if(destroyed) return;

    try {
      var message = JSON.parse(messageJSON);
    } catch (err) {
      var message = {};
      console.warn('Bridge dispatch failed. err = ' + err);
    }

    var messageHandler;

    if (message.responseId) {
      var responseCallback = responseCallbacks[message.responseId];

      if (!responseCallback) {
        return;
      } //isCBInProgress = true;

      responseCallback(message.responseData); //isCBInProgress = false;

      delete responseCallbacks[message.responseId];
    } else {
      var responseCallback;

      if (message.callbackId) {
        var callbackResponseId = message.callbackId;

        responseCallback = function (responseData) {
          _doSend({
            responseId: callbackResponseId,
            responseData: responseData,
          });
        };
      }

      var handler = window.WebViewJavascriptBridge._messageHandler;

      if (message.handlerName) {
        handler = messageHandlers[message.handlerName];
      }

      if (typeof handler === 'undefined') return; //isCBInProgress = true;

      for (var k in handler) {
        if (!handler.hasOwnProperty(k)) continue;

        try {
          handler[k](message.data, responseCallback);
        } catch (exception) {
          if (typeof console !== 'undefined') {
            console.log('WebViewJavascriptBridge: WARNING: javascript handler threw.', message, exception);
          }
        }
      } //isCBInProgress = false;
    }
  }

  function _handleMessageFromObjC(messageJSON) {
    // if (receiveMessageQueue) {
    //   receiveMessageQueue.push(messageJSON);
    // } else {
    //   _dispatchMessageFromObjC(messageJSON);
    // }
    _dispatchMessageFromObjC(messageJSON);
  }
  function webViewWillNavigate(messageJSON) {
    var event = new CustomEvent('webViewWillNavigate', { detail: messageJSON });
    if (window.dispatchEvent) {
      window.dispatchEvent(event);
    } else {
      window.fireEvent(event);
    }
  }

  function hasHandler(handlerName) {
    var has = false;

    try {
      has = null != messageHandlers[handlerName];
    } catch (exception) {
      has = false;
    } //On iOS, return String value only!

    return has ? 'true' : 'false';
  }

  function hasHandlerCB(handlerName) {
    if (!window.gabridge) return; //On Android, DO NOT RETURN ANYTHING!
    //Because Android can only call JS by loadUrl('javascript:....'), returned values WILL BECOME THE WEBPAGE AND WILL BE DISPLAYED!!!

    window.gabridge.onHasHandler(handlerName, hasHandler(handlerName));
  } // !!! NOTE: Currently only supported by iOS app >= 2.3. NOT SUPPORTED BY ANDROID APP!!!
  // This is a new way for App to inject handler definitions directly into JS context on page load.
  // In this way, JS won't need to chain callbacks for .callHandler('hasHandler', {handler: <handler>}, function(){.callHandler('<handler>', ... function(){});});
  // Chained JS calls could freeze iOS app if page reloads during the chained calls.

  function appHasHandler(name) {
    return window.WebViewJavascriptBridge._appHandlers && window.WebViewJavascriptBridge._appHandlers.indexOf(name) >= 0;
  }
  window.WebViewJavascriptBridge = {
    init: init,
    send: send,
    registerHandler: registerHandler,
    unregisterHandler: unregisterHandler,
    callHandler: callHandler,
    hasHandler: hasHandler,
    hasHandlerCB: hasHandlerCB,
    addHook: addHook,
    delHook: delHook,
    appHasHandler: appHasHandler,
    _fetchQueue: _fetchQueue,
    _handleMessageFromObjC: _handleMessageFromObjC,
    webViewWillNavigate: webViewWillNavigate,
    reset: reset, //reloadFromCacheIfNeeded: reloadFromCacheIfNeeded,
    // These are failed attempts to avoid iOS deadlock during page reloading. Kept for reference.
    //hasPending: hasPending,
    //destroy: function(){destroyed = true; responseCallbacks = []; receiveMessageQueue = [];},
  };

  var doc = document;

  _createQueueReadyIframe(doc);

  var readyEvent = doc.createEvent('Event'); // $FlowIgnoreNextLine because our bridge implementation is hacky

  readyEvent.initEvent('WebViewJavascriptBridgeReady');
  readyEvent.bridge = window.WebViewJavascriptBridge;
  doc.dispatchEvent(readyEvent);
})();
