import { Toast } from '@components/Toast';
import { ToastOptions } from 'react-toastify';

interface ICustomErrorConstructorOptions {
  code?: number;
  name?: string;
  ignore?: boolean;
  data?: any;
}

class CustomError extends Error {
  public code?: string | number;
  public ignore?: boolean;
  public data?: any;
  static from(error: unknown) {
    if (error instanceof CustomError) {
      return error;
    }
    if (error instanceof Error) {
      const { message, ...rest } = error;
      return new CustomError(message, { ...rest });
    }
    if (typeof error === 'string') {
      return new CustomError(error);
    }
    if (typeof error === 'object') {
      const { message, ...rest } = error as any;
      return new CustomError(message, { ...rest });
    }

    return new CustomError('Unknown error');
  }

  constructor(message?: string, options?: ICustomErrorConstructorOptions) {
    super(message);
    if (options?.code !== undefined) {
      this.code = options.code;
    }
    if (options?.name !== undefined) {
      this.name = options.name;
    }
    if (options?.ignore !== undefined) {
      this.ignore = options?.ignore;
    }
    if (options?.data !== undefined) {
      this.data = options?.data;
    }
  }

  toast(msg?: string, opts?: Partial<ToastOptions>) {
    if (this.ignore) {
      return;
    }
    this.ignore = true;
    return Toast.error(msg || this.message || '', opts);
  }
}

export default CustomError;
