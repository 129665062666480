import router from 'src/routes';
import { showDialogue } from '@components/Popup';
import i18next from 'i18next';
import { EEvent } from '@constants/event';
import { logout } from '@services/login';
import Router from '@utils/router';

interface IUserInfo {
  sign?: {
    publicKey: string;
    privateKey: string;
  };
  cert?: string;
  token?: string;
  expireTime?: number;
}

class UserManager {
  private KEYS = {
    SIGN: 'sign',
    CERT: 'cert',
    TOKEN: 'token',
    EXPIRE_TIME: 'expireTime',
  };

  public setSign(sign: IUserInfo['sign']) {
    const { SIGN } = this.KEYS;
    window.sessionStorage.setItem(SIGN, JSON.stringify(sign) || '');
  }

  public setToken(token: IUserInfo['token']) {
    const { TOKEN } = this.KEYS;
    window.sessionStorage.setItem(TOKEN, token || '');
  }

  public loginSuccess({ cert, token }: Omit<IUserInfo, 'sign' | 'expireTime'>) {
    const { CERT, TOKEN, EXPIRE_TIME } = this.KEYS;
    const expireTime = Date.now() + 15 * 60 * 1000; // 15 min login max session
    window.sessionStorage.setItem(CERT, cert || '');
    window.sessionStorage.setItem(TOKEN, token || '');
    window.sessionStorage.setItem(EXPIRE_TIME, String(expireTime));
    window.postMessage(EEvent.LoginSuccess);
  }

  public getUser(): Required<IUserInfo> {
    const { SIGN, CERT, TOKEN, EXPIRE_TIME } = this.KEYS;
    let sign = null;
    try {
      sign = JSON.parse(window.sessionStorage.getItem(SIGN) || '');
    } catch (_) {
      // do nothing
    }
    return {
      sign,
      cert: window.sessionStorage.getItem(CERT) || '',
      token: window.sessionStorage.getItem(TOKEN) || '',
      expireTime: Number(window.sessionStorage.getItem(EXPIRE_TIME) || 0),
    };
  }

  public isLogin() {
    const userInfo = this.getUser();
    return userInfo.cert && userInfo.token;
  }

  public clearUser() {
    Object.values(this.KEYS).forEach((key) => {
      window.sessionStorage.setItem(key, '');
    });
    Router.clear(); // clear all routes each time when logout
  }

  public async logout(params?: { showDialog?: boolean; url?: string }) {
    const { showDialog = false, url } = params || {};
    if (url) {
      // will leave h5 to BO page
      await logout();
      this.clearUser();
      window.location.replace(url);
      return;
    }

    // return to qr login page
    this.clearUser();
    router.navigate(`/qr-login${window.location.search}`, {
      replace: true,
    });
    if (showDialog) {
      setTimeout(() => {
        const dialog = showDialogue({
          title: i18next.t('popup_title_login_session_end_h5'),
          content: i18next.t('popup_desc_login_session_end_h5'),
          onConfirm: () => {
            dialog.close();
          },
        });
      }, 300);
    }
  }
}

export default new UserManager();
