import { Toast } from '@components/Toast';

const ignoreErrMsgs = [
  'Load failed', // ios `fetch` throws, currently not affect biz flow
];

window.addEventListener('unhandledrejection', (event) => {
  const error = event.reason;
  if (error.message && !ignoreErrMsgs.includes(error.message) && !error.ignore) {
    Toast.error(error.message);
  }
});

window.addEventListener('error', (event) => {
  const { error } = event;
  const msg = error.message;
  if (msg && !ignoreErrMsgs.includes(msg) && !error.ignore) {
    Toast.error(msg);
  }
});
