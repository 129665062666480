import { NavigateOptions, Location } from 'react-router-dom';
import routes from 'src/routes';
import UserManager from '@utils/user';
import md5 from 'crypto-js/md5';

function jumpPage(url: string, options?: NavigateOptions) {
  routes.navigate(`${url}${window.location.search}`, {
    replace: true,
    ...options,
  });
}

interface IRouterOptions extends Omit<NavigateOptions, 'replace'> {
  popCount?: number;
}

interface IPageStack {
  url: string;
  options?: Omit<IRouterOptions, 'popCount'>;
}

interface IRouter {
  push: (url: string, options?: IRouterOptions) => void;
  replace: (url: string, options?: IPageStack['options']) => void;
  goBack: () => void;
}

/**
 * [Usage]
 ** Router.replace(): used normally, replace the current page
 ** Router.push(): only used two scenes:
 *** 1. when PRD required to back to the current page; eg: pageA(current page) -> pageB -> pageA, then need to use Router.push(pageB)
 *** 2. when you sure the jump page will be the first page in the pageStacks;
 ** Router.goBack(): go back to the previous page; eg: pageA -> pageB(current page), use Router.goBack() will go back to pageA
 */
class Router implements IRouter {
  private KEYS = {
    PAGE_STACKS: 'pageStacks',
  };
  private stackKey: string = window.location.search ? md5(window.location.search).toString() : 'default'; // now `window.location.search` won't be empty

  private getPageStacks(): IPageStack[] {
    const { PAGE_STACKS } = this.KEYS;
    if (!this.stackKey) return [];
    const pageStacks = sessionStorage.getItem(PAGE_STACKS);
    if (!pageStacks) return [];

    try {
      return JSON.parse(pageStacks)?.[this.stackKey as string] || [];
    } catch (error) {
      return [];
    }
  }

  private savePageStacks(pageStacks: IPageStack[]) {
    const { PAGE_STACKS } = this.KEYS;
    window.sessionStorage.setItem(PAGE_STACKS, JSON.stringify({ [this.stackKey]: pageStacks }));
  }

  // return the pageStacks after pop
  private popPage(popCount = 1, shouldSavePageStacksAfterPop = true): IPageStack[] {
    const pageStacks = this.getPageStacks();
    if (popCount >= 1) {
      pageStacks.splice(-popCount, popCount);
    }
    if (shouldSavePageStacksAfterPop) {
      this.savePageStacks(pageStacks);
    }
    return pageStacks;
  }

  public clear() {
    const { PAGE_STACKS } = this.KEYS;
    window.sessionStorage.removeItem(PAGE_STACKS);
  }

  public push(url: string, options?: IRouterOptions) {
    const { popCount = 0, ...restOptions } = options || {};
    const pageStacks = this.popPage(popCount, false);

    pageStacks.push({ url, options: restOptions });
    this.savePageStacks(pageStacks);
    jumpPage(url, restOptions);
  }

  public replace(url: string, options?: IPageStack['options']) {
    this.push(url, { ...options, popCount: 1 });
  }

  public goLogin() {
    this.clear();
    jumpPage('/qr-login');
  }

  public goBack() {
    const pageStacks = this.popPage();
    const backPage = pageStacks[pageStacks.length - 1];
    if (backPage) {
      const { url, options } = backPage;
      jumpPage(url, options); // page has already been in stacks, no need to save it
    } else {
      // Backstory logic，if there is no back page, go to the login page
      this.goLogin();
    }
  }

  public checkPageCanAccess(location: Location<any>, needLogin = true) {
    if (!needLogin) {
      return true;
    }
    if (UserManager.isLogin()) {
      return true;
    }
    this.goLogin();
    return false;

    /**
     * below logic is used to avoid user using url in browser directly without egiro flow
     * but this logic may cause other problem, should discuss with PM about some edge cases like bo info change, annotation first
     **/
    // if (UserManager.isLogin()) {
    //   const path = window.location.pathname;
    //   const { state } = location;
    //   const pageStacks = this.getPageStacks();
    //   const pageStack = pageStacks.find((item) => item.url === path);
    //   if (pageStack) {
    //     if (!pageStack.options?.state) {
    //       // exist valid page, no need to check location state, do nothing

    //       return true;
    //     } else if (state) {
    //       /*
    //        * check if location state has value
    //        ** has: page reload, do nothing
    //        ** no: user may change the url, should go login page
    //        */
    //       return true;
    //     }
    //   }
    // }
    // no valid page in stack, return login page
    // this.goLogin();
    // return false;
  }
}

export default new Router();
