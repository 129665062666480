import { fetchRequest } from './index';
import CustomError from '@utils/CustomError';
import i18next from 'i18next';
import { showDialogue } from '@components/Popup';
import router from 'src/routes';

/**
 * login(used by input mobile + pin)
 */
export async function login(body: Omit<IMobileLoginReq, 'loginScene'>) {
  const res = await fetchRequest<IMobileLoginRes>({
    url: '/h5/uapi/v1/pre/login',
    params: {
      method: 'POST',
      body: {
        ...body,
        loginScene: 'MOBILE',
      },
    },
    needLogin: false,
    fingerprint: true,
  });
  if (res.otPin === '1' || res.otPwd === '1') {
    const dialog = showDialogue({
      content: i18next.t('popup_desc_one_time_tag_h5'),
      onConfirm: () => {
        dialog.close();
        router.navigate(`/qr-login${window.location.search}`, {
          replace: true,
        });
      },
    });
    const err = new CustomError();
    err.ignore = true;
    throw err;
  }
  return res;
}

/**
 * get login id
 */
export async function getLoginId(body: IGetLoginIdReq) {
  try {
    return await fetchRequest<IGetLoginIdRes>({
      url: '/h5/uapi/v1/login/id',
      params: {
        method: 'POST',
        body,
      },
      needLogin: false,
    });
  } catch (err) {
    const error = CustomError.from(err);
    error.toast(i18next.t('toast_get_login_id_fail'));
    throw err;
  }
}

/**
 * query login info
 */
export function queryLoginInfo(body: IQueryLoginInfoReq) {
  return fetchRequest<IQueryLoginInfoRes>({
    url: '/h5/uapi/v1/login/info',
    params: {
      method: 'POST',
      body,
    },
    needLogin: false,
    showLoading: false,
  });
}

/**
 * query login result
 */
export function queryLoginSession(body: IQueryLoginSessionReq) {
  return fetchRequest<IQueryLoginSessionRes>({
    url: '/h5/uapi/v1/login/session',
    params: {
      method: 'POST',
      body,
    },
    needLogin: false,
    showLoading: false,
  });
}

/**
 * temp, remove later
 */
export function getSubUserList() {
  return fetchRequest<any>({
    url: '/h5/uapi/sme/v1/sub-users-list',
    params: {
      method: 'GET',
    },
    needLogin: true,
    showLoading: true,
  });
}

/**
 * temp, remove later
 */
export function switchUser() {
  return fetchRequest<any>({
    url: '/h5/uapi/sme/v1/no-risk/switch-account',
    params: {
      method: 'POST',
      body: {
        userType: 2,
        encryptedUid: 'S8M4h_4qSLHqXjgZc72gnA==',
      },
    },
    needLogin: true,
    showLoading: true,
  });
}

export async function logout() {
  try {
    await fetchRequest<any>({
      url: '/h5/uapi/v2/logout',
      params: {
        method: 'POST',
      },
      needLogin: true,
      showLoading: false,
    });
  } catch (error) {
    // do nothing
  }
}
