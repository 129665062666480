export const COLORS = {
  PRIMARY: '#EA5F00',
  INACTIVE_PRIMARY: 'rgba(241, 82, 49, 0.1)',
  WARNING: '#EE2C4A',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  BLUE: '#2673DD',
  GREEN: '#26AA43',
  RED: '#EE4D2D',
  BACKGROUND: '#F7F8F9',
  MODAL_BACKGROUND: 'rgba(0,0,0,0.5)',
  AVATAR_BACKGROUND: '#FAFAFA',
  GREY: '#707070',
  DISABLE: '#BDBDBD',
  LIGHT_GREY: '#E8E8E8',
  LIGHT_GREY2: '#FAFAFA',
  DISABLED_TEXT: '#ABABAB',
  DISABLED_BOX: '#F0F0F0',
  TEXT_LIGHT: 'rgba(0,0,0,0.2)',
  TEXT_GRAY: 'rgba(0,0,0,0.4)',
  TEXT_PRIMARY: 'rgba(0, 0, 0, 0.87)',
  TEXT_PRIMARY_LIGHT: 'rgba(0, 0, 0, 0.8)',
  TEXT_SECONDARY: 'rgba(0,0,0,0.65)',
  TEXT_TERTIARY: 'rgba(0, 0, 0, 0.54)',
  TEXT_PLACEHOLDER: 'rgba(0, 0, 0, 0.2)',
  TEXT_WHITE_TITLE: 'rgba(255, 255, 255, 0.87)',
  TEXT_DIM_GRAY: 'rgba(101, 101, 101, 0.2)',
  TEXT_LINK: '#467FDF',
  TEXT_LINK_2: '#4080EE',
  TEXT_ORANGE: '#EA5F00',
  TEXT_NOTICE: '#F69113',
  TEXT_SECONDARY_DARK: 'rgba(0, 0, 0, 0.65)',
  TEXT_LIGHT_BLUE: '#457CD7',
  ACTIVE_HIGHT_LIGHT: '#F0F1F1',
  TRANSPARENT: 'rgba(0,0,0,0)',
  BTN_LIGHT: 'rgba(0,0,0,0.09)',
  BORDER_LINE: 'rgba(0,0,0,0.08)',
  BORDER_LINE_LIGHT: 'rgba(0,0,0,0.04)',
  BORDER_DRAWER_MENU_LINE: 'rgba(0, 0, 0, 0.1)',
  BORDER_BUTTON_STROKE: '#E0E0E0',
  BG_GREEN: '#4095A5',
  BG_DARK: '#214D7E',
  BG_BROWN: '#DB944B',
  BG_BLUE: '#5688E1',
  BG_ORANGE: '#FFF4F4',
  BG_LIGHT_ORANGE: '#FFF6F5',
  BG_LIGHT_ORANGE_2: 'rgba(251, 226, 209, 0.5)',
  BG_YELLOW: '#FFF8E4',
  BG_LIGHT_GREY: '#F8F9FA',
  BG_LIGHT_GREY_ANDROID: '#F5F6F7',
  BG_LIGHT_GREEN: '#F7FFFE',
  BG_LIGHT_GREEN_2: '#F0F8FF',
  BG_GRAY: '#F6F7F8',
  CIRCLE_GRAY: '#AAAAAA',
  ORANGE: '#F15332',
  BORDER_ARVATAR: 'rgba(255,255,255,0.5)',
  BORDER_TEXT_ARVATAR: 'rgba(0,0,0,0.09)',
  BORDER_WIDTH_COLOR: 'rgba(0,0,0,0.09)',
  INPUT_UNDERLINE: 'rgba(0,0,0,0.09)',
  BORDER_SHOPEE_ARVATAR: 'rgba(0,0,0,0.12)',
  MASK_GRAY: 'rgba(0,0,0,0.4)',
  PLACEHOLDER_TEXT_COLOR: 'rgba(0, 0, 0, 0.26)',
  INPUT_AMOUNT_PLACEHOLDER_TEXT_COLOR: 'rgba(0,0,0,0.09)',
  ERROR_BACKGROUND: '#FFF4F4',
  ERROR: '#EE2C4A',
  SHADOW_LIGHT: 'rgba(0, 0, 0, 0.06)',
  SECTION_BG: '#F5F5F5',
  BG_KEYBOARD: '#F6F7F8',
  TEXT_DEEPBLACK: '#202325',
  BG_SMALL_ORANGE: '#FFF6F5',
  BG_SMALL_BLUE: '#F0F8FF',
  PAYNOW_QR_PURPLE: '#7C1A78',
  SHADOW_COLOR: '#5e5e5e',
  SLIDER_BACKGROUND: '#E5E5E5',
  TITLE_BLACK: '#202325',
  BELL_BORDER_WHITE: 'rgba(255,255,255,0.15)',
  LIMIT_ERR_BTN: '#3a74d6',
  CVP_NAVIGATION_BAR: '#EC6308',
  RETAIL_CPV_NAVIGATION_BAR: '#EA5F00',
  BG_LIGHT_RED: '#FFF6F5',
  BORDER_GREY_FIVE: '#D0D0D0',
  PRIMARY_GRADIENT: '#F88D44',
  PRIMARY_GRADIENT_FADE: 'rgba(248, 141, 68, 0)',
  BG_SKELETON_LOADING: '#ECECEC',
  TEXT_LIGHT_GREY: '#757575',
  LIGHT_BLUE_BG: '#F7FFFE',
  SUCCESS_GREEN: '#30B566',
  UTILITY_LINE: '#00000017',
  BORDER_GREY_LIGHT: 'rgba(132,132,132,0.10)',
  SHADOW_ORANGE: '#FFF4EE',
  BORDER_BOTTOM_COLOR: '#F6F6F6',
  BG_DEEP_ORANGE: '#E9BBBB',
  BG_DEEP_BLUE: '#CBE0F9',
  BG_DEEP_YELLOW: '#FBC8A9',
  BG_DEEP_GREEN: '#A9DAD4',
  SHADOW_ORANGE_COLOR: '#C64B06',
  TEXT_SHADOW_PRIMARY: '#C64B06',
  TRANSPARENT_WHITE: 'rgba(255,255,255, 0)',
  BORDER_GREY_LIGHT_COLOR: '#F3F3F3',
  SWITCH_ON: '#4DD865',
  BG_DARK_PRIMARY: 'rgba(0, 0, 0, 0.87)',
  SHADOW_BLUE: 'rgba(51, 64, 79, 0.09)',
  INPUT_BOX_BORDER: '#E5E6E7',
  STEP_LINE: '#F6E0CE',
  TEXT_PRIMARY_900: 'rgba(16, 24, 40, 1)',
};
