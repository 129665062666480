import i18next from 'i18next';

export const LANGUAGE = 'en';

export const createI18n = async () => {
  i18next.init({
    lng: LANGUAGE,
    resources: {
      [LANGUAGE]: { translation: require(`../../public/strings/${LANGUAGE}.json`) },
    },
    keySeparator: false,
  });
  return i18next;
};

export default i18next;
