import { useEffect } from 'react';
import UserManager from '@utils/user';
import { EEvent } from '@constants/event';

const useLoginSession = () => {
  useEffect(() => {
    let intervalTimer: number | undefined = undefined;

    const checkLoginSession = () => {
      // check if user session is expired every 5s
      intervalTimer = window.setInterval(() => {
        const { expireTime } = UserManager.getUser();
        // if user manually log out, stop interval timer
        if (!expireTime) {
          window.clearInterval(intervalTimer);
          return;
        }
        // if current time > expireTime, logout & stop interval timer
        if (Date.now() > expireTime) {
          UserManager.logout({ showDialog: true });
          window.clearInterval(intervalTimer);
        }
      }, 5000);
    };

    const handleLoginSuccess = (event: MessageEvent<any>) => {
      if (event.data === EEvent.LoginSuccess) {
        checkLoginSession();
      }
    };

    if (UserManager.isLogin()) {
      checkLoginSession();
    }

    window.addEventListener('message', handleLoginSuccess);

    return () => {
      window.clearInterval(intervalTimer);
      window.removeEventListener('message', handleLoginSuccess);
    };
  }, []);
};

export default useLoginSession;
