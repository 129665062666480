export interface BrowserObject {
  isIos: boolean;
  isAndroid: boolean;
  isMobile: boolean;
  isPc: boolean;
  isHuawei: boolean;
}

export function getBrowserObject(ua: string): BrowserObject {
  const browser = {} as BrowserObject;
  const isIos = /(iphone|ipad|ipod|ios)/i.test(ua);
  const isAndroid = /(android|linux)/i.test(ua);
  const isHuawei = /huawei/i.test(ua);

  browser.isIos = isIos;
  browser.isAndroid = isAndroid;
  browser.isHuawei = isHuawei;

  browser.isMobile = isIos || isAndroid || isHuawei;
  browser.isPc = !isIos && !isAndroid && !isHuawei;

  return browser;
}

export function detectBrowser(userAgent?: string): BrowserObject {
  if (typeof window !== 'undefined') {
    const ua = window.navigator.userAgent.toLowerCase();
    return getBrowserObject(ua);
  }
  if (userAgent) {
    return getBrowserObject(userAgent);
  }
  return {
    isIos: false,
    isAndroid: false,
    isMobile: false,
    isHuawei: false,
    isPc: true,
  };
}
