import { useState, useEffect, Suspense } from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';
import { RouterProvider } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Sentry } from '@banking/web-apms';
import routes from './routes';
import I18n, { createI18n } from '@core/i18n';
import useLoginSession from '@hooks/useLoginSession';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  const [i18n, setI18n] = useState(I18n);

  useLoginSession();

  useEffect(() => {
    if (!I18n.isInitialized) {
      createI18n().then((i18nInstance) => setI18n(i18nInstance));
    } else if (!i18n) {
      setI18n(I18n);
    }
  }, []);

  return (
    <Sentry.ErrorBoundary>
      <div className="app">
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={null}>
            <RouterProvider router={routes} />
          </Suspense>
        </I18nextProvider>
      </div>
      <ToastContainer
        transition={cssTransition({ enter: 'animate__fadeIn', exit: 'animate__fadeOut' })}
        limit={1}
        icon={({ type }) => {
          if (type === 'success') {
            return <img src={require('@assets/images/toast_success.png')} />;
          } else {
            return <img src={require('@assets/images/toast_error.png')} />;
          }
        }}
      />
    </Sentry.ErrorBoundary>
  );
}

export default App;
