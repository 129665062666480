import { ToastOptions, ToastPosition, toast } from 'react-toastify';
import { COLORS } from '@constants/style';
import Loading from '@components/Loading';
import LoadingManager from './loadingManager';
import styles from './index.module.less';

export enum EToastType {
  SUCCESS = 1,
  ERROR = 2,
  INFO = 3,
}

const commonProps: ToastOptions = {
  closeButton: false,
  autoClose: 1000,
  pauseOnHover: false,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  position: 'top-center' as ToastPosition,
  style: { backgroundColor: COLORS.TEXT_SECONDARY, color: COLORS.WHITE, margin: 24, borderRadius: 4 },
};

const Toast = ({ content, type, props }: { content: string; type: EToastType; props: Partial<ToastOptions> }) => {
  switch (type) {
    case EToastType.SUCCESS:
      toast.success(content, {
        ...commonProps,
        ...props,
      });
      break;
    case EToastType.ERROR:
      toast.error(content, {
        ...commonProps,
        ...props,
      });
      break;
    case EToastType.INFO:
    default:
      toast.info(content, {
        ...commonProps,
        ...props,
      });
  }
};

Toast.success = (content: string, props?: Partial<ToastOptions>) =>
  toast.success(content, {
    ...commonProps,
    ...(props ? props : {}),
  });
Toast.error = (content: string, props?: Partial<ToastOptions>) =>
  toast.error(content, {
    ...commonProps,
    ...(props ? props : {}),
  });
Toast.info = (content: string, props?: Partial<ToastOptions>) =>
  toast.info(content, {
    ...commonProps,
    ...(props ? props : {}),
  });

const TOAST_LOADING_ID = 'toast_loading';
const loadingManager = new LoadingManager({
  showLoading(msg?: string) {
    if (toast.isActive(TOAST_LOADING_ID)) {
      toast.update(TOAST_LOADING_ID, {
        render: <Loading content={msg} />,
      });
      return;
    }
    toast(<Loading content={msg} />, {
      ...commonProps,
      autoClose: false,
      icon: false,
      style: undefined,
      className: styles.loading,
      toastId: TOAST_LOADING_ID,
      draggable: false,
    });
  },
  closeLoading() {
    toast.dismiss(TOAST_LOADING_ID);
  },
});

Toast.showLoading = (content?: string, control?: { delay?: number }) => {
  const loading = loadingManager.showLoading({ text: content, delay: control?.delay });
  return {
    close: () => {
      loading.close();
    },
  };
};

export { Toast };
