import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

const QRLogin = lazy(() => import('@pages/Login/QRLogin'));
const InputMobile = lazy(() => import('@pages/Login/InputMobile'));
const InputPin = lazy(() => import('@pages/Login/InputPIN'));
const LoginInterim = lazy(() => import('@pages/Login/LoginInterim'));
const PDFView = lazy(() => import('@pages/PDFView'));
const SetUpEGiro = lazy(() => import('@pages/EGiro/SetUpEGiro'));
const SetUpEGiroReview = lazy(() => import('@pages/EGiro/SetUpEGiroReview'));
const SetUpEGiroNotification = lazy(() => import('@pages/EGiro/SetUpEGiroNotification'));
const DeleteEGiro = lazy(() => import('@pages/EGiro/DeleteEGiro'));
const EGiroResult = lazy(() => import('@pages/EGiro/EGiroResult'));

export default createBrowserRouter([
  {
    path: '/qr-login',
    element: <QRLogin />,
  },
  {
    path: '/input-mobile',
    element: <InputMobile />,
  },
  {
    path: '/input-pin',
    element: <InputPin />,
  },
  {
    path: '/login-interim',
    element: <LoginInterim />,
  },
  {
    path: '/setup-egiro',
    element: <SetUpEGiro />,
  },
  {
    path: '/pdf-view',
    element: <PDFView />,
  },
  {
    path: '/setup-egiro-review',
    element: <SetUpEGiroReview />,
  },
  {
    path: '/setup-egiro-notification',
    element: <SetUpEGiroNotification />,
  },
  {
    path: '/delete-egiro',
    element: <DeleteEGiro />,
  },
  {
    path: '/egiro-result',
    element: <EGiroResult />,
  },
]);
