import { fetchRequestBase, FetchRequestBaseParams, fetchRequestBlob } from '@banking/web-sdk/service/base';
import { encryptData } from '../constants/encrypt';
import initSecureFetch, { getDfp } from '../utils/init-secure-fetch';
import UserManager from '@utils/user';
import { Toast } from '@components/Toast';
import { EFetchErrorCode, RSA_PUBLIC_KEY_ERROR } from '@constants/error';
import { APP_ID, CHANNEL_AUTH } from '@constants/core';
import user from '@utils/user';
import CustomError from '@utils/CustomError';
import i18next from 'i18next';

const ENCRYPT_BODY_RSA_KEY = 'ENCRYPT_BODY_RSA_KEY';

/*
 * public key rotation logic:
 * 1.The code maintains five sets of public keys
 * 2.The first request uses the first matched public key and records (0 < current timestamp - version < 366days). Subsequent requests use this public key
 * 3.If the backend returns 1000003, 3000005, 3000006, 3000007, get shopee-banking-encrypt-version, and FE matches and finds the corresponding public key (the situation where it cannot be found will basically not occur)
 * Note: 3 is unlikely to occur in H5, so we won't handle it
 **/
const setRsaVersion = (version: string) => {
  window.sessionStorage.setItem(ENCRYPT_BODY_RSA_KEY, version);
};

const getRsaInfo = () => {
  const storeRsaVersion = window.sessionStorage.getItem(ENCRYPT_BODY_RSA_KEY);
  if (storeRsaVersion) {
    const info = encryptData.encryptBodyRsa.find((item) => item[0] === storeRsaVersion);
    return {
      version: info?.[0],
      key: info?.[1],
    };
  }
  const currTime = Date.now();
  const info = encryptData.encryptBodyRsa.find((item) => {
    const diff = currTime - Number(item[0]);
    return diff > 0 && diff <= 366 * 24 * 60 * 60 * 1000;
  });
  setRsaVersion(info?.[0] || '');
  return {
    version: info?.[0],
    key: info?.[1],
  };
};

export const getApiHost = () => {
  const ENV = process.env.env || 'live';

  const API_DOMAIN = 'maribank.com.sg';

  if (ENV.includes('uat')) return `https://hapi-${ENV}.uat.npt.${API_DOMAIN}`;

  if (ENV.includes('sit')) return `https://hapi-${ENV}.test-stable.npt.${API_DOMAIN}`;

  if (ENV.includes('dev')) return `https://hapi-${ENV}.test.npt.${API_DOMAIN}`;

  if (ENV.includes('staging')) return `https://hapi.${ENV}.${API_DOMAIN}`;

  return `https://hapi.${API_DOMAIN}`;
};

/**
 * sap: request sign(tss platform, not web-service)
 * dfp: devFingerPrint
 */
export const fetchWithoutSapDfp = async ({ url, params, needSign, signParams }: Omit<FetchRequestBaseParams, 'encryptBodyKeyInfo'>) => {
  const rsaInfo = getRsaInfo();
  return await fetchRequestBase({
    url,
    params: {
      ...params,
      headers: {
        'shopee-banking-appid': APP_ID,
        'shopee-banking-channel-auth': CHANNEL_AUTH,
        ...params.headers,
      },
    },
    encryptBodyKeyInfo: {
      rsaKey: rsaInfo?.key || '',
      rsaVersion: rsaInfo?.version || '',
    },
    needSign,
    signParams,
  });
};

type TRequestServiceParams = {
  url: FetchRequestBaseParams['url'];
  params: FetchRequestBaseParams['params'];
  needLogin?: boolean;
  showLoading?: boolean;
  loadingDelay?: number;
  fingerprint?: boolean;
};

export const fetchRequest = async <T>({
  url,
  params: reqParams,
  needLogin = false,
  showLoading = true,
  loadingDelay = 1000,
  fingerprint = false,
}: TRequestServiceParams): Promise<T> => {
  const loading = showLoading ? Toast.showLoading(undefined, { delay: loadingDelay }) : undefined;
  try {
    const params: Omit<FetchRequestBaseParams, 'encryptBodyKeyInfo'> = {
      url: `${getApiHost()}${url}`,
      params: reqParams,
    };

    if (fingerprint && params.params?.body) {
      const rdVerifyInfo = params.params?.body.rdVerifyInfo;
      params.params.body.rdVerifyInfo = {
        deviceFingerprint: await getDfp(),
        ...(rdVerifyInfo ? rdVerifyInfo : {}),
      };
    }
    await initSecureFetch;

    if (needLogin) {
      params.needSign = true;
      const { sign, cert, token } = UserManager.getUser();

      params.signParams = {
        privateKey: sign?.privateKey,
        cert,
        token,
      };

      params.params.headers = {
        'shopee-banking-authorization': token,
        ...(params.params.headers ? params.params.headers : {}),
      };
    }
    const res = await fetchWithoutSapDfp(params);

    return res.data;
  } catch (err: any) {
    const { httpCode, code, message, httpStatusText, resHeaders } = err;
    const error = CustomError.from({
      ...err,
      code: code ?? httpCode,
      message: message ?? httpStatusText,
    });

    if ([EFetchErrorCode.CLIENT_TIMEOUT].includes(err.code) || [EFetchErrorCode.NotFound].includes(err.httpCode)) {
      error.toast(i18next.t('error_server_no_available'));
    }

    if (RSA_PUBLIC_KEY_ERROR.includes(err.code as number)) {
      const version = resHeaders.get('Shopee-Banking-Encrypt-Version');
      if (version) {
        setRsaVersion(version);
        return await fetchRequest({
          url,
          params: reqParams,
          needLogin,
          showLoading,
          loadingDelay,
          fingerprint,
        });
      }
    }

    if (
      [EFetchErrorCode.InvalidateSign, EFetchErrorCode.InvalidateSign2].includes(error.code as EFetchErrorCode) ||
      [EFetchErrorCode.Unauthorized].includes(httpCode)
    ) {
      error.ignore = true;
      user.logout({ showDialog: true });
    }
    throw error;
  } finally {
    loading?.close();
  }
};

type IDownloadServiceParams = {
  url: string;
  type: 'download' | 'arrayBuffer';
};
export const downloadFile = async ({ url, type = 'download' }: IDownloadServiceParams) => {
  try {
    return await fetchRequestBlob({
      url: `${getApiHost()}${url}`,
      params: {
        headers: {
          'shopee-banking-authorization': UserManager.getUser().token,
          'shopee-banking-appid': APP_ID,
          'shopee-banking-channel-auth': CHANNEL_AUTH,
        },
      },
      type,
    });
  } catch (err: any) {
    const { httpCode, code, message, httpStatusText } = err;

    const error = CustomError.from({
      ...err,
      code: code ?? httpCode,
      message: message ?? httpStatusText,
    });

    if ([EFetchErrorCode.CLIENT_TIMEOUT].includes(err.code) || [EFetchErrorCode.NotFound].includes(err.httpCode)) {
      error.toast(i18next.t('error_server_no_available'));
    }

    if ([EFetchErrorCode.Unauthorized].includes(httpCode)) {
      error.ignore = true;
      user.logout({ showDialog: true });
    }

    throw error;
  }
};
