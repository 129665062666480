// nonlive & live is same
const RSA_PUBLIC_KEY = [
  'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMuW+klOCNv8FsqHZPy4bTtx9sxpcQ5/jc/8wjEze9FW3NPfeFwXoDTd2hhpFsanJ+5LzZ3hpRQIauOJz3MaBYUCAwEAAQ==',
  'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAO4exMWkSX6ypkU1fr0emk6ux0kCI7K/B46dLNrLqDiEqQuI4AGipj3i05mgyPMI0TwBJaQZi8ANGfCWzXEhVG8CAwEAAQ==',
  'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAK2HhlLhdYUqPeMr2GYUHR3OFCxZ4SL7jBw7PF59XCdiOrzL0zP07rk+E/qPBuPiWxXrZiLgdIVd4yjm3IiWZO8CAwEAAQ==',
  'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAJiseAcG37xlBBh5F4uCxWTcuNiIOtf1c81rA5wmV3lFQGFR8BzVZh7RqJr5NvCM0MEv6qZ9dIvqaNKSr5DvDnECAwEAAQ==',
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA18PArhvryKQvKVxEGwGtnqQsPbFCIE+BwxA6cmczcsuQFyNMBLta4+NRpAki8Wq/TrmLEoP/ehuwRBFypGE1k3JL42eEACBTrNNsEGlBn981kW5zsqMDSkmNH5wtedOsoqJWxHoMwWOoL97sYuf5VBo+bdl724R4/6XJZmnb+i/xAi0QfnQQHbq8tfgDJ5G1Py2hN/77ugF0JrXIqG2enwr5NH1LOSrPrFatNKnkhIwy+5xRJ4rjQrehM8nw99enQK/LAaTQqFccbSwAdjMForMR0v9l3QzQN7jLSayeOJFLzV4Jp7+uqh7SBrcHiw8uNXb7UwbJFRnRtKY2q7hq/QIDAQAB',
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArKi6Tu52tPhlX3qnnEKHSwAEyQB4hQZTHmKkThyaoqWMkEvvrfpORu8Ov3U0xtd+aC1is/sjae0BfVMhmxrw6MhWKUXlyOcZGhL4v96t8+eFM0QJdzqajpOcxj1Y8djiBSuiTP0WN9DCvwXBDbf6K7bFFz22GREowzhhe1r0yMeFl9S3X5pMQYPtikp7GZw1NcLmFRwqGdCSUu1sg1r1DpWBpmzWoOFm2YAjvWgLNzKHpt99vOhb9aKJ8t5S22rquLntytHnXl/tn9ELWpuV9sMRRMc9RKdwXu/ieyjRi2X9KZpjX4lfcerd0EZ/cZ9P4udAmr3UfmrIQwD4K5uROQIDAQAB',
];

export const encryptData = {
  // 加密 body 使用的公钥与版本
  encryptBodyRsa:
    !process.env.env || process.env.env === 'live'
      ? [
          ['1696953600000', RSA_PUBLIC_KEY[0]],
          ['1728576000000', RSA_PUBLIC_KEY[1]],
          ['1760112000000', RSA_PUBLIC_KEY[2]],
          ['1791648000000', RSA_PUBLIC_KEY[3]],
          ['1823184000000', RSA_PUBLIC_KEY[4]],
          ['1854806400000', RSA_PUBLIC_KEY[5]],
        ]
      : [
          ['1696953600000', RSA_PUBLIC_KEY[0]],
          ['1725984000000', RSA_PUBLIC_KEY[1]],
          ['1757520000000', RSA_PUBLIC_KEY[2]],
          ['1789056000000', RSA_PUBLIC_KEY[3]],
          ['1820592000000', RSA_PUBLIC_KEY[4]],
          ['1852214400000', RSA_PUBLIC_KEY[5]],
        ],
  // 加密 PIN 的公钥数据
  encryptPinRsa:
    !process.env.env || process.env.env === 'live'
      ? {
          publicKeyH: '5',
          rsaH: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAw0Tbj58OeTsWtpnOgduVHWI1WgvhQTZ2KFHgRlDoJ601uH6gMFgi8LY3iYgQ0e2Wyn+dXu3z74atQoCrUqEqLMqcoC1mxc1CMDi5NDQkWUDV2UnwXDC29x3VJ0LYPA1rUusY/IEbnQnD8/1K36DTHZNzElD2SHtM7eSZMYLGPdBuZ9JrTW1OQj5fbTFcVZ4UWepUT/EJripZzuNuGnm4HV+sBUo3B/8gCOcDlOMagCa2yBiTeaKxz5L2bm7u6KETyNyoEW5MiCGp8j8L9Ko4IWJ38xkBfjDrY+fMDMji9M0NYmFzBOiGJXRrJANfyCp7Aoobgm75ofRdqbhDKfh+0QIDAQAB',
          publicKeyP: '3',
          rsaP: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsGYLzIRrEUSbDh6PfnV+DAc6tI6p6nknUQ1lAOa9Y9sjgbeKAFlr1194N/LN0s51j+TYMXoJiFBwfOSGrT2Tg/pitw6WRipD5Yajnp+13Jj5LD1h6HPUKMVn1O8bMLpuhHf7ICl4vTQZJNGah5VoXGF9xwdH6lUzEcvEtiKV7mMWQBAJjlslFn8zWaCkP6fm/RLUVwfolij5Kk+MGlshxsnYrQVvdrKpCjO/hZI2Zal3m1PguyKCC6SzJ+DL407gBINePgAna13lOtK1+kDqOF2sUhmEcXwRkhoRad290rMDUzFXsmkaUm+nPl8bGR6dtl5r4YZkd+w3BSLWYatPRwIDAQAB',
        }
      : {
          publicKeyH: '3',
          rsaH: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3q/T0bSKkyfDEKJvxrz6cluTUxF1lgFInx5KpPf7J1TKHIHHP0GiWTAmRe1seEPHA9eM4WOLYt7L5RBIw7CXIu+rCNUSqESM6+/bXmh1P6aWv7vBSi3miBq0qcyMpJqkJaxwvjYb5IFUakm4TF6e44kswZAvPgILL51bStwRrauL8J83+ddOk08MzmSRwGTq8ucFNItPHKOfhIdudr9PNFXGc9w8t7cKj1K9itftfJtpcr30Di1FQc1NaTCpwP5ZVkKihC/FkWM87kJ3eWlCqxYS33Vf9hAhQ9RZglkc9gjoUAgax91xPMMIDR6kSYuLn350cd9SqSwxqJyIYkbjnwIDAQAB',
          publicKeyP: '2',
          rsaP: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAql9hKiFG/Fxdk0hXFpl5gxEvFUzdhjfvZMVGb/chhN2m7jPqrPoCdM7SHc7Vf6tYC35cTLV1csUhJUz34L0iJo3naTrNZuotBEzNALfjoLP/U9KY0Utk4wNM/xFjepQUrEm36i3G9GllLDZuQ8HvFJZdBUL12Fnh26Dnj3cAVK28zf7LOhZh7nrDmt1X9zliM6oQXyL7qK5Tf+S+9zfNkfcik8WicUp1PPnKOtK3uXqvrGLqqrQHFFHDDr9qEBmosCbd0kGqnSK6tV1rtKVEuT21pw3S4jNjAs8qdmoQTIfswymP6jE6f/7hDBEBhg9UoOF6yGactLF/kmrgPVcObwIDAQAB',
        },
};
